body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: local('SpaceGrotesk'), url(/static/media/SpaceGrotesk-SemiBold.85caebdc.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
   background-color: #F3F1EF; 
/*  background-color: white;*/
  padding: 38px;
}

p, a {
/*   color: #b600ff; */
/*   color: #7d4ee3; */
  color: rgba(0, 0, 0, .8);
  font-family: 'SpaceGrotesk', Helvetica, sans-serif;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 4.72vw;
  line-height: 5.13vw;
  transition: 0.3s ease-out;
}

.heading {
/*  transform: scale(0);*/
}

p {
/*  margin-top: 0;*/
/*  margin-bottom: 7.63vw;*/
  margin-top: 7.63vw;
  margin-bottom: 0;
  letter-spacing: -0.2vw;
  max-width: 80vw;
}

p:first-of-type {
  margin-top: 0;
}

a {
/*  color: #7d4ee3;*/
  color: #7d4ee3;
  text-decoration: none;
  position: relative;
  /* display: inline-block; */
  /* overflow: hidden; */
}

a:hover {
  /* text-decoration: none; */
}

a::after {
  content: '';
	position: absolute;
  bottom: 0;
	left: 0;
	width: 100%;
	height: 0.09em;
	background-color: #7d4ee3;
	opacity: 0;
	transition: opacity 300ms, -webkit-transform 300ms;
	transition: opacity 300ms, transform 300ms;
	transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
  -webkit-transform: translate3d(0, -0.1em, 0);
          transform: translate3d(0, -0.1em, 0);
}

a:hover::after,
a:focus::after {
  opacity: 1;
	-webkit-transform: translate3d(0, 0em, 0);
	        transform: translate3d(0, 0em, 0);
}

.badges {
/*  display: inline;*/
  margin-top: 32px;
}

.badge {
  background-color: #7d4ee3;
  color: #F3F1EF;
  font-size: 1.73vw;
  line-height: 3.5vw;
  vertical-align: middle;
  border-radius: 0.69vw;
  display: inline-block;
  height: 3.5vw;
  padding: 0 1.38vw;
  margin-left: 1.2vw;
  letter-spacing: 0;
}

.badge:first-of-type {
  margin-left: 0;
}

iframe {
  width: 100vw;
  height: 100vh;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  position: fixed;
  /* bottom: -20vh; */
  /* left: 22vw; */
  top: 0;
  left: 0;
  background-color: white;
  border-radius: 10px;
  border: none;
  transition: 0.3s ease-in-out;
  -webkit-filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.45));
          filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.45));
  display: none;
}

.webImg {
  width: 50vw;
  height: auto;
  position: fixed;
  /* bottom: -20vh; */
  /* left: 22vw; */
  bottom: 40px;
  right: 40px;
  background-color: white;
  border-radius: 6px;
  border: none;
  transition: 0.5s ease-in-out;
  -webkit-filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
  opacity: 1;
  -webkit-transform: translate3d(120%, 0, 0);
          transform: translate3d(120%, 0, 0);
}

.visible {
  opacity: 1;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.topics {
  margin-top: 7.63vw;
  display: flex;
  align-items: center;
}

.topic {
  display: inline-block;
  margin: 0;
  cursor: pointer;
}

.topic a {
  color: rgba(0, 0, 0, .8);
}

.topic a:hover {
  color: #7d4ee3;
}

.topic a::after {
  display: none;
}



.topic:last-of-type {
  margin-left: 3vw;
}

.topic.current, .topic:hover  {
  color: #7d4ee3;
}

.imgSection {
  display: flex;
  flex-wrap: wrap;
}

.imgSection img {
  /*max-width: 44vw;
  margin-top: 40px;
  border-radius: 6px;
  max-height: 90vh;
  margin-right: 40px;
  align-self: center;*/

  max-width: 80vw;
  margin-top: 40px;
  border-radius: 6px;
  max-height: 90vh;
  margin-right: 40px;
  align-self: center;
}

p.small {
  font-size: 20px;
  letter-spacing: 0;
}

.backToTop {
  color: rgba(0, 0, 0, .8);
}

.backToTop:hover {
  color: #7d4ee3;;
}

.backToTop::after {
  display: none;
}

@media screen and (max-width: 768px) {
  body {
    padding: 16px;
  }

  p, a {
    font-size: 30px;
    line-height: 38px;
    -webkit-hyphens: auto;
            hyphens: auto;
    hyphenate-before: 10;
    hyphenate-after: 10;
    max-width: 100%;
  }

  p.mainP {
    margin-bottom: 20px;
  }

  .badges {
    margin-bottom: 60px;
  }

  p.mainP:last-of-type {
    margin-bottom: 240px;
  }

  a:after {
    /* opacity: 1; */
    /* transform: none; */
  }

  a {
    color: black;
  }

  a.visible {
    color: #7d4ee3;
  }

  a.visible:after {
    opacity: 1;
  	-webkit-transform: translate3d(0, 0em, 0);
  	        transform: translate3d(0, 0em, 0);
  }

  .badges {
    display: block;
    margin-top: 10px;
  }

  .badge {
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    height: 24px;
    padding: 0 12px;
    margin-right: 10px;
    margin-left: 0;
  }

  .webImg {
    width: calc(100vw - 20px);
    z-index: 100;
    left: 10px;
    bottom: 10px;
    -webkit-filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  }

  .visible {
  }

  .imgSection img {
    max-width: 100%;
    margin-top: 20px;
    border-radius: 4px;
    margin-right: 20px;
  }
}

